<template>
  <x-form-validator ref="refFormObserver">
    <x-form-feild label="数据视图配置">
      <x-form-repeater
        ref="repeater"
        :data.sync="formData.optionalFields"
        no-actions
        class="ml-1"
        :is-first="true"
        :isView="isView"
        :isNew="isNew"
      >
        <template
          slot="form-repeater"
          slot-scope="{item, index}"
        >
          <div class="d-flex w-100 justify-content-between ">
            <b-row class="w-100 d-flex align-items-center">
              <b-col md="3">
                <b-form-input
                  v-model="item.name"
                  placeholder="请输入字段名"
                  :readonly="isView"
                />
              </b-col>
              <b-col md="3">
                <b-form-input
                  v-model="item.describe"
                  placeholder="请输入描述"
                  :readonly="isView"
                />
              </b-col>
              <b-col
                md="2"
                v-if="!isView"
              >
                <b-button
                  v-ripple.400="$x.ripple.danger"
                  variant="flat-danger"
                  size="sm"
                  class="btn-light-danger"
                  pill
                  @click="removeItem(index)"
                >
                  <span class="align-middle">删除配置</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </template>
      </x-form-repeater>
    </x-form-feild>
    <b-button class="ml-1" variant="primary" v-ripple.400="$x.ripple.primary" @click="add" v-if="!isView && unadd">保存</b-button>
  </x-form-validator>
</template>
<script>
import { BButton, BRow, BCol, BFormInput } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import { add } from '@/api/system/setup/permission'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BButton,
    XFormRepeater,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 未新增
      unadd: true,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    add() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          add(this.formData).then(() => {
            this.$xtoast.success('编辑成功')
            this.unadd = false
          })
        }
      })
    },
    removeItem(index) {
      if (this.$refs.repeater) {
        this.$refs.repeater.removeItem(index)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
