<template>
  <x-form-validator ref="refFormObserver">
    <x-form-feild label="权限标识（ID）" require>
      <b-form-input
        v-model="formData.id"
        placeholder="请输入权限标识"
        :readonly="!isNew"
      />
    </x-form-feild>
    <x-form-feild label="权限名称" require>
      <b-form-input
        v-model="formData.name"
        placeholder="请输入权限名称"
        :readonly="isView"
      />
    </x-form-feild>
    <x-form-feild label="状态" require>
      <div class="demo-inline-spacing">
        <b-form-radio
          v-model="formData.status"
          name="status"
          value="1"
          class="custom-control-success"
          :disabled="isView"
        >
          启用
        </b-form-radio>
        <b-form-radio
          v-model="formData.status"
          name="status"
          value="0"
          class="custom-control-danger"
          :disabled="isView"
        >
          禁用
        </b-form-radio>
      </div>
    </x-form-feild>
    <x-form-feild label="分类">
      <v-select
        v-model="formData.properties.type"
        :options="types"
        label="name"
        multiple
        placeholder="请选择分类"
        :reduce=" option => option.id"
        :disabled="isView"
      >
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <x-form-feild label="操作配置">
      <x-form-repeater
        ref="repeater"
        :data.sync="formData.actions"
        :isView="isView"
        :isNew="isNew"
        no-actions
        class="ml-1"
        :is-first="true"
      >
        <template
          slot="form-repeater"
          slot-scope="{item, index}"
        >
          <div class="d-flex w-100 justify-content-between ">
            <b-row class="w-100 d-flex align-items-center">
              <b-col md="3">
                <b-form-input
                  v-model="item.action"
                  placeholder="操作类型"
                  :readonly="isView"
                />
              </b-col>
              <b-col md="3">
                <b-form-input
                  v-model="item.name"
                  placeholder="名称"
                  :readonly="isView"
                />
              </b-col>
              <b-col md="3">
                <b-form-input
                  v-model="item.describe"
                  placeholder="描述"
                  :readonly="isView"
                />
              </b-col>
              <b-col
                md="2"
                v-if="!isView"
              >
                <b-button
                  v-ripple.400="$x.ripple.danger"
                  variant="flat-danger"
                  size="sm"
                  class="btn-light-danger"
                  pill
                  @click="removeItem(index)"
                >
                  <span class="align-middle">删除配置</span>
                </b-button>
              </b-col>
          </b-row>
          </div>
        </template>
      </x-form-repeater>
    </x-form-feild>
    <b-button class="ml-1" variant="primary" v-ripple.400="$x.ripple.primary" @click="add" v-if="!isView && unadd">保存</b-button>
  </x-form-validator>
</template>
<script>
import { BFormInput, BButton, BFormRadio, BRow, BCol } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import vSelect from 'vue-select'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import { add } from '@/api/system/setup/permission'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BFormInput,
    BButton,
    BFormRadio,
    vSelect,
    XFormRepeater,
    BRow,
    BCol,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 未新增
      unadd: true,
      types: [
        { id: 'default', name: '默认' },
        { id: 'system', name: '系统' },
        { id: 'business', name: '业务功能' },
        { id: 'api', name: 'API接口' },
        { id: 'tenant', name: '多租户' },
      ],
    }
  },
  watch: {
    isNew: {
      immediate: true,
      handler() {
        if (this.isNew && this.formData.actions.length === 0) {
          let actions = [
            {
              action: 'query',
              name: '查询列表',
              describe: '查询列表',
            }, {
              action: 'get',
              name: '查询明细',
              describe: '查询明细',
            }, {
              action: 'add',
              name: '新增',
              describe: '新增',
            }, {
              action: 'update',
              name: '修改',
              describe: '修改',
            }, {
              action: 'delete',
              name: '删除',
              describe: '删除',
            }, {
              action: 'import',
              name: '导入',
              describe: '导入',
            }, {
              action: 'export',
              name: '导出',
              describe: '导出',
            },
          ]
          actions.forEach(action => {
            this.formData.actions.push(action)
          })
        }
      },
    },
  },
  methods: {
    add() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          add(this.formData).then(() => {
            this.$xtoast.success('编辑成功')
            this.unadd = false
          })
        }
      })
    },
    removeItem(index) {
      if (this.$refs.repeater) {
        this.$refs.repeater.removeItem(index)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
