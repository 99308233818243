<template>
  <x-form-validator ref="refFormObserver">
    <x-form-feild label="关联配置">
      <x-form-repeater
        ref="repeater"
        :data.sync="formData.parents"
        no-actions
        class="ml-1"
        :is-first="true"
        :isView="isView"
        :isNew="isNew"
      >
        <template
          slot="form-repeater"
          slot-scope="{item, index}"
        >
          <div class="d-flex w-100 justify-content-between ">
            <b-row class="w-100 d-flex align-items-center">
              <b-col md="3">
                <v-select
                  v-model="item.actions"
                  :options="operates"
                  label="name"
                  :getOptionLabel="option => option.name + `（${option.id})`"
                  multiple
                  placeholder="请选择前置操作"
                  :reduce=" option => option.id"
                  :disabled="isView"
                >
                  <template slot="no-options">{{$t('noData')}}</template>
                </v-select>
              </b-col>
              <b-col md="3">
                <v-select
                  v-model="item.permission"
                  :options="permissions"
                  label="name"
                  :getOptionLabel="option => option.name"
                  placeholder="请选择关联权限"
                  :reduce=" option => option.id"
                  :disabled="isView"
                >
                  <template slot="no-options">{{$t('noData')}}</template>
                </v-select>
              </b-col>
              <b-col md="3">
                <v-select
                  v-model="item.preActions"
                  :options="operates"
                  label="name"
                  :getOptionLabel="option => option.name + `（${option.id})`"
                  multiple
                  placeholder="请选择关联操作"
                  :reduce=" option => option.id"
                  :disabled="isView"
                >
                  <template slot="no-options">{{$t('noData')}}</template>
                </v-select>
              </b-col>
              <b-col
                md="2"
                v-if="!isView"
              >
                <b-button
                  v-ripple.400="$x.ripple.danger"
                  variant="flat-danger"
                  size="sm"
                  class="btn-light-danger"
                  pill
                  @click="removeItem(index)"
                >
                  <span class="align-middle">删除配置</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </template>
      </x-form-repeater>
    </x-form-feild>
    <b-button class="ml-1" variant="primary" v-ripple.400="$x.ripple.primary" @click="add" v-if="!isView && unadd">保存</b-button>
  </x-form-validator>
</template>
<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import vSelect from 'vue-select'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import { add, forGrant } from '@/api/system/setup/permission'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BButton,
    vSelect,
    XFormRepeater,
    BRow,
    BCol,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 未新增
      unadd: true,
      operates: [
        { id: 'query', name: '查询' },
        { id: 'save', name: '保存' },
        { id: 'delete', name: '删除' },
        { id: 'import', name: '导入' },
        { id: 'export', name: '导出' },
      ],
      // 待关联权限数组
      permissions: [],
    }
  },
  watch: {
  },
  created() {
    forGrant().then(resp => {
      this.permissions = resp.data.result
    })
  },
  methods: {
    add() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          add(this.formData).then(() => {
            this.$xtoast.success('编辑成功')
            this.unadd = false
          })
        }
      })
    },
    removeItem(index) {
      if (this.$refs.repeater) {
        this.$refs.repeater.removeItem(index)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
