<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      vertical
      active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
      nav-wrapper-class="nav-vertical"
      class="p-2">
      <b-tab
        active
        title="基本信息">
        <base-info
          v-if="tabIndex === 0"
          :isNew="isNew"
          :isView="isView"
          :formData="formData"
        />
      </b-tab>
      <b-tab
        title="关联权限">
        <relation-info
          v-if="tabIndex === 1"
          :isNew="isNew"
          :isView="isView"
          :formData="formData"
        />
      </b-tab>
      <b-tab
        title="数据视图"
        lazy
      >
        <data-view
          v-if="tabIndex === 2"
          :isNew="isNew"
          :isView="isView"
          :formData="formData"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import baseInfo from './baseInfo.vue'
import relationInfo from './relationInfo.vue'
import dataView from './dataView.vue'

export default {
  components: {
    BTabs,
    BTab,
    baseInfo,
    relationInfo,
    dataView,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  watch: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
</style>
