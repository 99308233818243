import request from '@/auth/jwt/useJwt'

// 获取用户数据
export function query(pageIndex, pageSize, params) {
  let requestUrl = `permission/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  let index = 0
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index += 1
    }
    if (params.id) {
      requestUrl += `&terms[${index}].column=id$LIKE&terms[${index}].value=%${params.id}%`
      index += 1
    }
    if (params.status) {
      requestUrl += `&terms[${index}].column=status&terms[${index}].value=${params.status}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
/*  新增权限  */
export function add(data) {
  return request.axiosIns({
    url: '/permission',
    method: 'PATCH',
    data,
  })
}
/* 删除权限 */
export function remove(id) {
  return request.axiosIns({
    url: `/permission/${id}`,
    method: 'DELETE',
  })
}
// 查询关联权限
export function forGrant() {
  return request.axiosIns({
    url: '/permission/_query/for-grant',
    method: 'get',
  })
}
