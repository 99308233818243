<template>
  <div>
    <x-table
      title="权限列表"
      ref="table"
      :card="true"
      customXForm
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowView="rowView"
      @rowDelete="rowDelete"
      @export="exportHandle">
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      title="编辑权限"
      right
      backdrop
      shadow
      width="85%"
      :visible="visibleFlag"
      @hidden="sideReset"
    >
      <permission-form
        v-if="visibleFlag"
        :isNew="isNew"
        :isView="isView"
        :formData="formData"
      />
    </b-sidebar>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BSidebar,
} from 'bootstrap-vue'
import { query, remove, forGrant } from '@/api/system/setup/permission'
import permissionForm from './permissionForm.vue'

export default {
  components: {
    XTable,
    BSidebar,
    permissionForm,
  },
  data() {
    return {
      formData: {
        id: undefined,
        name: undefined,
        status: 1,
        properties: {
          type: [],
        },
        actions: [],
        optionalFields: [],
        parents: [],
      },
      visibleFlag: false,
      isView: false,
      isNew: false,
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: true,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', icon: 'EyeIcon', customXForm: true },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/equipment-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon', customXForm: true },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          // { action: 'disable', name: '禁用', icon: 'SlashIcon' },
        ],
        actionFilter: action => action === 'view' || action === 'edit' || action === 'delete',
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入标识',
          },
        }, {
          label: '名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入权限名称',
          },
        }, {
          label: '状态',
          labelShow: true,
          prop: 'status',
          type: 'select',
          addShow: false,
          editShow: false,
          viewShow: false,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop === 0) {
              return 'text-danger'
            }
            return 'text-success'
          },
          dictData: [{ text: '启用', value: 1 }, { text: '禁止', value: 0 }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
          searchShow: true,
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    addHandle() {
      this.isNew = true
      this.isView = false
      this.visibleFlag = true
    },
    rowEdit(data) {
      this.isNew = false
      this.isView = false
      this.formData = this._.cloneDeep(data)
      if (!this.formData.properties) {
        this.formData.properties = {
          // type: [],
        }
      }
      if (this.formData.properties && !this.formData.properties.type) {
        this.formData.properties.type = undefined
      }
      this.visibleFlag = true
    },
    rowView(data) {
      this.isNew = false
      this.isView = true
      this.formData = data
      if (!this.formData.properties) {
        this.formData.properties = {
          type: [],
        }
      }
      if (this.formData.properties && !this.formData.properties.type) {
        this.formData.properties.type = undefined
      }
      this.visibleFlag = true
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    sideReset() {
      this.isNew = false
      this.isView = false
      this.visibleFlag = false
      this.formData = {
        id: undefined,
        name: undefined,
        status: 1,
        properties: {
          type: [],
        },
        actions: [],
        optionalFields: [],
        parents: [],
      }
      this.$refs.table.__searchHandle()
    },
    exportHandle() {
      forGrant().then(resp => {
        if (resp.status === 200) {
          this.downloadObject(resp.data.result, '权限数据')
          this.$xtoast.success('导出成功')
        } else {
          this.$xtoast.error('导出错误')
        }
      })
    },
    downloadObject(record, fileName) {
      const eleLink = document.createElement('a')
      eleLink.download = `${fileName}.json`
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      const blob = new Blob([JSON.stringify(record)])
      eleLink.href = URL.createObjectURL(blob)
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
